import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import styles from "./sectionJury.module.scss"
import { IsMobileDevice } from "../../../../../utils"

// Components
import Title from "../Title"
import SvgIcon from "../../../SvgIcon"
import Section from "../../../Section"
import BudCover from "../../../TextBudStage"
import InfluencerCard from "../InfluencerCard"

import memeback from "../../../../../images/BudStage/home/meme-back.png"
import renataBack from "../../../../../images/BudStage/home/renata-back.png"

import memeImage from "../../../../../images/BudStage/home/meme-image.png"
import renataImage from "../../../../../images/BudStage/home/renata-image.png"

const SectionJury = ({ imagenes }) => {
  const [isMobile, setIsMobile] = useState(false)

  console.log("imagesss", imagenes)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const jury = [
    {
      name: "MEME BOUQUET.",
      role: "ARTISTA/DJ",
      city: "Buenos Aires, Arg",
      spotify:
        "https://open.spotify.com/user/11131778754?si=GT_MAUleTwKLOqKsMUwF2Q",
      instagram: "https://instagram.com/memebouquet?igshid=YmMyMTA2M2Y=",
      alt: "MEME BOUQUET SOCIAL",
      influencerImage: <img src={memeImage} className={styles.imageArtist} />,
      backImage: <img src={memeback} className={styles.imageback} />,
      className: styles.memeBou,
      index: 1,
    },
    {
      name: "RENATA REPETTO.",
      role: "ARTISTA/CANTANTE",
      city: "Buenos Aires, Arg",
      spotify:
        "https://open.spotify.com/artist/4CiNVxmxNrjW15HdqR26pH?si=cDcMck0MRGWYIjeqiBEWig",
      instagram: "https://instagram.com/renatarepetto?igshid=YmMyMTA2M2Y=",
      alt: "RENATA REPETTO SOCIAL",
      influencerImage: <img src={renataImage} className={styles.imageArtist} />,
      backImage: <img src={renataBack} className={styles.imageback} />,
      className: styles.renataRepetto,
      index: 2,
    },
  ]

  return (
    <Section sectionName="jury" className={styles.jurySection}>
      <SvgIcon name="bud-redform-icon" className={styles.redformIcon} />
      <BudCover orientation="vertical" className={styles.budText} />
      <Title title="JURADO" className={styles.title} x />
      <div className={styles.whiteStripe}></div>

      <div className={styles.juryContainer}>
        {jury.map(item => {
          return <InfluencerCard {...item} />
        })}
      </div>
    </Section>
  )
}

export default SectionJury
