import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useParallax } from "react-scroll-parallax"
import styles from "./sectionAwards.module.scss"
import Section from "../../../Section"
import { IsMobileDevice } from "../../../../../utils"

import imageDesktop from "../../../../../images/BudStage/home/stage.png"
import imageMobile from "../../../../../images/BudStage/home/scenario-mobile.png"

const SectionAwards = () => {
  const [isMobile, setIsMobile] = useState()
  const parallaxImage = useParallax({
    speed: -5,
  })
  const parallaxText = useParallax({
    speed: 4,
  })

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  })

  return (
    <Section sectionName="awards" className={styles.imageSection}>
      {isMobile ? (
        <div className={styles.imageSectionBody}>
          <img src={imageMobile} />
          <div className={styles.imageSectionText}>
            <p>EL GANADOR TOCARÁ COMO TELONERO EN CAMPING BA</p>
            <p>
              Si tu tema es de los más votados y elegido por nuestro jurado, vas
              a poder tocar solo o con tu banda en vivo como telonero de un
              artista BUD en una fecha única en Camping BA.
            </p>
            <p>
              BUDWEISER<span>X</span>BUD STAGE
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.imageSectionBody}>
          <div className={styles.backgroundSquare}>
            <span ref={parallaxImage.ref}>
              <img src={imageDesktop} />
            </span>
          </div>

          <div
            ref={parallaxText.ref}
            className={styles.imageSectionText}
            style={{ right: 0 }}
          >
            <p>EL GANADOR TOCARÁ COMO TELONERO EN CAMPING BA</p>
            <p>
              Si tu tema es de los más votados y elegido por nuestro jurado, vas
              a poder tocar solo o con tu banda en vivo como telonero de un
              artista BUD en una fecha única en Camping BA.
            </p>
            <p>
              BUDWEISER <span>{" X "} </span> BUD STAGE
            </p>
          </div>
        </div>
      )}
    </Section>
  )
}

export default SectionAwards
