import React, { useRef, useState, useEffect } from "react"
import styles from "./slider.module.scss"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"
import { Pagination, EffectFade } from "swiper"
import { IsMobileDevice } from "../../../../../../utils"
import microMouthSecundary from "../../../../SvgIcon/svg/asset-25.svg"
import SvgIcon from "../../../../SvgIcon"

import "swiper/swiper.scss" // core Swiper
import "swiper/modules/pagination/pagination.scss" // Pagination module
import "../../../../../../styles/slider.css"

const StepSlider = ({ handleChange }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer}>
        <Swiper
          pagination={{
            type: "fraction",
          }}
          modules={[Pagination, EffectFade]}
          // effect="fade"
          /*     spaceBetween={isMobile ? 0 : -450} */
          slidesPerView={isMobile ? 1 : 3}
          autoplay={{ delay: 10000 }}
          onSlideChange={e => handleChange(e.realIndex)}
          // onSwiper={(swiper) => console.log(swiper)}
          /*         style={{
                  height: "100%",
                }} */
        >
          <>
            <SwiperSlide>
              <SvgIcon
                name="caricature-icon"
                size="33.846vw"
                className={styles.icon1}
              />
              <h1>
                GRABÁ <br />
                TU VIDEO
              </h1>
              <p>
                Filmate a vos o con tu banda <br /> tocando un tema suyo.
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container}>
                <SvgIcon
                  name="fire-lips-icon"
                  size="36.851vw"
                  className={styles.icon2}
                />
                <h1>
                  SUBÍ TU <br />
                  TEMA Y <br />
                  PARTICIPÁ
                </h1>
                <p>
                  Compartí tu tema y <br />
                  difundilo para recibir votos.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container}>
                <img className={styles.icon3} src={microMouthSecundary} />
                <h1>
                  TOCÁ EN <br />
                  VIVO EN <br />
                  CAMPING BA
                </h1>
                <p>
                  El ganador tocará como <br /> telonero en Camping BA.
                </p>
              </div>
            </SwiperSlide>
          </>
        </Swiper>
      </div>
    </div>
  )
}

export default StepSlider
