import React, { useState, useEffect } from "react";
import styles from './title.module.scss';
import { IsMobileDevice } from '../../../../../utils';

const Title = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(IsMobileDevice());
  }, []);
  const { title, className, x } = props;

  return (<p className={`${styles.title} ${className}`}>
    {title}
    {x && <span>X</span>}
  </p>)

}

export default Title;