import React, { useState, useEffect } from "react"
import styles from "./influencer-card.module.scss"
import { useParallax } from "react-scroll-parallax"
import { IsMobileDevice } from "../../../../../utils"
import SvgIcon from "../../../SvgIcon"

const InfluencerCard = props => {
  const [isMobile, setIsMobile] = useState(false)
  const [viewh, setViewh] = useState(0)

  const {
    name,
    influencerImage,
    backImage,
    className,
    role,
    city,
    index,
    instagram,
    alt,
    spotify,
  } = props

  const speed1 = isMobile ? 0 : -2.5

  const prlxname = useParallax({
    speed: speed1,
    startScroll: viewh * 4 + 100,
    endScroll: viewh * 4 + 800,
  })

  const speed2 = isMobile ? 0 : 2.5

  const prlxfooter = useParallax({
    speed: speed2,
    startScroll: viewh * 4 + 500,
    endScroll: viewh * 4 + 800,
  })

  const translate = isMobile
    ? ["0px", "0px"]
    : index === 1
    ? ["0px", "30px"]
    : ["30px", "0px"]

  const prlxjury = useParallax({
    translateX: translate,
    startScroll: viewh * 4 + 100,
    endScroll: viewh * 4 + 800,
  })

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    setViewh(window.innerHeight)
  }, [])

  return (
    <div className={styles.influencerCard}>
      <div ref={prlxname.ref} className={styles.name}>
        <p>{name}</p>
      </div>

      <div
        className={`${styles.influencerImage} ${className}`}
        ref={prlxjury.ref}
      >
        <span className={styles.backImg}>{backImage}</span>
        {influencerImage}
      </div>

      <div className={styles.influencerCardFooter} ref={prlxfooter.ref}>
        <div className={styles.influencerCardFooterText}>
          <p>{role}</p>
          <p>{city}</p>
        </div>
        <div className={styles.influencerCardFooterMedia}>
          <a href={instagram} alt={alt} target="_blank">
            <SvgIcon
              name="instagram-white-icon"
              size={isMobile ? "3.765vw" : "1.319vw"}
            />
          </a>

          <a href={spotify} alt={alt} target="_blank">
            <SvgIcon
              name="spotify-icon"
              size={isMobile ? "3.765vw" : "1.319vw"}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default InfluencerCard
